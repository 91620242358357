<template>
  <div class="page">
    <div class="header-container">
      <div class="back-item">
        <router-link class="content link" to="/login">{{ $t('General.Cancel') }}</router-link>
      </div>
    </div>
    <div class="header-space"></div>
    <div>
      <label class="title">{{ $t('Reset.Title') }}</label>
    </div>
    <div class="header-space"></div>
    <div class="login-form">
      <div>
        <TextBox :placeholder="$t('Reset.Mail')" :type="'email'" :value="email" @input="email = $event"></TextBox>
      </div>
      <div>
        <TextBox :placeholder="$t('Reset.Password')" :type="'password'" :value="password1" @input="password1 = $event"></TextBox>
      </div>
      <div>
        <TextBox :placeholder="$t('Reset.Repeat')" :type="'password'" :value="password2" @input="password2 = $event"></TextBox>
      </div>
      <div @click="reset()">
        <Button :text="$t('Reset.Submit')"></Button>
      </div>
    </div>
  </div>
</template>

<script>
import TextBox from '../components/TextBox.vue'
import Button from '../components/Button.vue'

export default {
  name: 'Start',
  components: { TextBox, Button },
  data() {
    return {
      token: '',
      email: '',
      password1: '',
      password2: '',
    };
  },
  mounted() {
    if (!this.$route.query.token || !this.$route.query.email) return
    this.token = this.$route.query.token
    this.email = this.$route.query.email
    this.$router.replace('/reset')
  },
  methods: {
    async reset() {
      if (!this.valid) {
        this.toast('Fehler', 'Ungültige Eingabe', 'danger')
        return
      }
      // Authorizes user from the backend
      this.axios
        .post('auth/reset', {
          email: this.email,
          password: this.password1,
          token: this.token,
        })
        .then((res) => {
          if (res.status !== 200)
            return this.toast(
              'Es ist ein Fehler aufgetreten',
              'Ungültige Logindaten',
              'danger'
            );
          this.$router.push('/login')
        })
        .catch(() => {
          this.toast('Fehler', 'Ungültige Daten', 'danger')
        })
    },
  },
  computed: {
    pwdValid() {
      return this.pwd.length > 6
    },
    mailValid() {
      const regex = RegExp(/^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,8}$/)
      return regex.test(this.$store.state.mailTo)
    },
    valid() {
      return (
        this.email &&
        this.password1 &&
        this.password2 &&
        this.password1 === this.password2
      )
    },
  },
};
</script>

<style lang="scss" scoped>
.login-form {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
</style>
